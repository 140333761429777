import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Aseguradora Solidaria de Colombia"
      src="/static/solidaria_logo_blanco.png"
      style={{ maxHeight: 52, maxWidth: 200, marginLeft: 15 }}
      {...props}
    />
  );
}

export default Logo;
