import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Aseguradora Solidaria de Colombia"
      src="/static/solidaria_logo.png"
      style={{ maxHeight: 150, maxWidth: '95%' }}
      {...props}
    />
  );
}

export default Logo;
